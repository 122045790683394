@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

html,
body,
head,
p,
h1,
h2,
h3,
ul,
ol,
dl,
dt,
dd,
li,
div {
  padding: 0;
  margin: 0;
}
body {
  color: #12171d;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 1.2;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}

button {
  border: 0 none;
  background: transparent;
}
